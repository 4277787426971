.section-pages-faq {
    max-width: 765px;
    width: 96.09375%;
    margin: 0 auto 30px;
}

.section-pages-faq .section-title {
    font-size: var(--font-size-heading1);
    line-height: 1;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.section-pages-faq .term {
    margin-bottom: 20px;
}

.section-pages-faq .term .heading {
    margin-bottom: 20px;
}

.section-pages-faq .term .b-icon::before {
    content: '\22c1';
    display: inline-block;
    float: right;
    font-family: 'IconsOri';
    font-size: var(--font-size-icon3);
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    transition: transform .2s ease-in;
}

.section-pages-faq .term .expanded.b-icon::before {
    transform: rotate(180deg);
    color: var(--primary-main);
}

.section-pages-faq .term:first-child {
    padding-top: 18px;
    border-top: 2px solid var(--grey-100);
}

.section-pages-faq .term:last-child {
    border-bottom: 2px solid var(--grey-100);
}

.section-pages-faq .content a {
    text-decoration: underline;
}

/* BP 1 */
@media screen and (max-width: 479px) {
    .section-pages-faq {
        width: 93.75%;
    }
}

/* BP 1-2 */
@media only screen and (max-width: 767px) {
    .section-pages-faq .section-title {
        font-size: var(--font-size-h4);
        line-height: 1.2;
        margin-bottom: 25px;
    }

    .section-pages-faq .heading {
        display: block;
    }
}

/* BP 2 */
@media screen and (min-width: 480px) and (max-width: 767px){
    .section-pages-faq {
        width: 95%;
    }
}